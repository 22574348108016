<template>
    <div class="h-full ob-t">
        <div class="organization-box">
			<div style=" background-color:#f3f3f3">
				<div class="menu-type">
                    <el-input class="tree-search" v-model="filterText" show-word-limit placeholder="请输入字典名称" />
				</div>
				<div class="menu-add">
					<span @click="addTree" >
						<span>&nbsp;</span><i class="el-icon-plus"></i>
					</span>
				</div>
			</div>
			<div class="scroll-bar item">
				<PubliceTree
					ref="customTree"
					:itemShow="treeConfig.treeShow"
					:tree-data="treeConfig.treeData"
					:tree-expand-all="treeConfig.treeExpandAll"
					:tree-node-key="treeConfig.treeNodeKey"
					:checkedID="treeConfig.treeCheckedId"
                    @handleNodeClick="handleNodeClick"
					@addItem="addTreeItem"
					@deleteItem="deleteTreeItem"
					@editItem="editTreeItem"
				></PubliceTree>
			</div>
		</div>

        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam" v-if="!config.isDetailDisplay">
            <div slot="buttonGroup" class="h-handle-button">
                <div class="h-b"><el-button type="primary" size="small" @click="addSysDic" v-right-code="'Sysdictionary:Save'">新增</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="deleteMultiple" v-right-code="'Sysdictionary:Delete'">删除</el-button> </div>
            </div>
            <p slot="elList">
                <el-table ref="sysDicListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams"
                                     :key="index"
                                     :prop="col.FieldName"
                                     :label="col.DisplayName"
                                     :render-header="bindFilter(queryParam,col)"
                                     :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                     :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                     v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName==='DictionaryName'"><span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                            <span v-else>  {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" :min-width="150" v-if="!config.isDetailDisplay">
						<template slot-scope="scope">
							<el-button @click="deleteSingle(scope.row)" size="small" type="text" v-right-code="'Sysdictionary:Delete'">删除</el-button>
						</template>
					</el-table-column>
                </el-table>
            </p>
        </fixed-list>

        <DialogEx :options="editDicConfig.viewOptions" title="字典操作" @onOk="editDicOk">
			<EditDic ref="editDic" :config="editDicConfig.dataOptions"></EditDic>
		</DialogEx>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.Event.$on("reloadSysDicTreeList", () => this.getTreeList());
            this.Event.$on("reloadSysDicPageList", () => this.reloadPageList());
            this.Event.$on("onAddSysDic", () => this.addSysDic());
            this.initialize();
            this.getTreeList();
        },
        data() {
            return {
                tUserOptions: {
                    visible: false
                },
                multipleSelection: [],
                isPassValidate: true,
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },
                SkuCode: '',
                treeConfig:{
                    treeShow: true,
                    treeExpandAll: false,
                    treeNodeKey: 'DictionaryId',
                    treeCheckedId: '',
                    treeData: [],
                    selectTreeNode: null,
                    selectKeyword:''
                },
                filterText:'',
                editDicConfig: {
                    viewOptions:{
                        visible: false,
                        width: '60%',
                        modal: true
                    },
                    dataOptions:{
                        dataSourceKey:null,
                        dataSource:{
                            DictionaryParent:null,
                            DictionaryParentName:null
                        }
                    }
                },
            }
        },
        watch:{
            filterText(val) {
                this.$refs.customTree.$refs.tree.filter(val);
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
                isButtonlDisplay: false,
                dataSourceKey:null
            },
            option: {}
        },
        methods: {
            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onCheckRow", this.multipleSelection);
            },
            onPageChange(param) {
                var _this = this;
                this.queryParam = param;
                this.$ajax.query("omsapi/sysdictionary/getpagelist", "post", param, data => {
                    _this.dataSource = data;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });
            },
            rowClick(row) {
                this.onChangeEditDataSource(row.DictionaryId);
            },
            deleteMultiple() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择要删除的字典.`, "error");
                    return false;
                }
                var ids = this.Utils.selectionsToArr(this.multipleSelection, "DictionaryId");
                this.deleteDic(ids);
            },
            deleteSingle(row){
                var ids=[row.DictionaryId];
                this.deleteDic(ids);
            },
            deleteDic(ids){
                var me = this;
                this.Utils.confirm({
                    title: "提示：",
                    content: "删除后数据不可恢复，是否确认删除?"
                }, () => {
                    this.$ajax.send("omsapi/sysdictionary/delete", "post", ids, (data) => {
                        me.onPageChange(me.queryParam);
                        me.Utils.messageBox("删除成功.", "success");
                    });
                }, () => {
                    me.Utils.messageBox("已取消.", "info");
                });
            },
            addSysDic() {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", null);
            },
            onChangeEditDataSource(dataSourceKey) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", dataSourceKey);
            },
            addTree(){
                this.addTreeItem(null);
            },
            addTreeItem(data){
                console.log(data)
                if (data != null) {
                    this.editDicConfig.dataOptions.dataSource.DictionaryParent = data.DictionaryId;
                    this.editDicConfig.dataOptions.dataSource.DictionaryParentName = data.DictionaryName;
                } else {
                    this.editDicConfig.dataOptions.dataSource.DictionaryParent = null;
                    this.editDicConfig.dataOptions.dataSource.DictionaryParentName = null;
                }
                this.editDicConfig.dataOptions.dataSourceKey = null;
                this.editDicConfig.viewOptions.visible = true;
            },
            editTreeItem(data){
                this.editDicConfig.dataOptions.dataSourceKey = data.DictionaryId;
                this.editDicConfig.viewOptions.visible = true;
            },
            deleteTreeItem(data){
                var me = this;
                this.Utils.confirm({
                    title: "提示：",
                    content: "删除后字典项，子字典及子字典项将连同删除，请确认?"
                }, () => {
                    this.$ajax.send("omsapi/sysdictionary/deletedic", "post", data.DictionaryId, (data) => {
                        me.getTreeList();
                        me.Utils.messageBox("删除成功.", "success");
                    });
                }, () => {
                    me.Utils.messageBox("已取消.", "info");
                });
            },
            //  查询左侧tree下拉树结构
            getTreeList() {
                var _this = this;
                this.$ajax.send('omsapi/sysdictionary/gettreelist', 'get', {}, data => {
                    if(data.IsSuccess){
                        this.treeConfig.treeData = data.Result;
                        if(data.Result.length>0){
                            this.treeConfig.treeCheckedId = this.treeConfig.treeData[0].DictionaryId;
                            this.treeConfig.selectTreeNode = this.treeConfig.treeData[0];
                        }
                    }
                });
            },
            editDicOk(option){
                this.$refs.editDic.save(option);
            },
            handleNodeClick(data){
                this.selectTreeNode = data;
                this.queryParam.Params.DictionaryParent = data.DictionaryId;
                this.reloadPageList();
            }
        },
        components: {
            "PubliceTree": resolve => { require(['./tree.vue'], resolve) },
            "EditDic": resolve => { require(['./editsysdic.vue'], resolve) }
        }
    }
</script>
<style>
.ob-t {
	display: flex;
}

.ob-t .h-table-list {
	padding-top: 10px;
}

.organization-box {
	/* background-color: #fbfbfb; */
	border: 1px solid #e5e6eb;
	min-width: 250px;
    width: 250px;
	display: flex;
	flex-direction: column;
}
.menu-type{
	width:186px; float:left
}
.menu-type .el-tabs__item{
	padding: 0 10px !important;
}
.menu-add{
    margin-top: 10px;
	width: 30px;
    height: 24px;
	line-height: 24px;
    float: right;
    text-align: center;
	cursor: pointer;
}
.organization-box .btn-add {
	background-color: #f5f7fa;
	height: 36px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* margin: 0 4% 10px; */
	padding: 0 15px;
	color: #666;
	cursor: pointer;
}

.organization-box .btn-add i {
	font-size: 12px;
	font-weight: bold;
	color: #333;
}

.organization-box .tree-search {
	width: 92%;
	margin: 10px 4%;
}

.organization-box .scroll-bar {
	flex: 1;
	height: 0;
}

.organization-box .white-body-view {
	height: 100%;
	overflow-y: auto;
}

.organization-box .el-tree {
	background-color: transparent;
}

.organization-box .el-tree .el-tree-node__content {
	display: flex;
	flex-direction: row;
	height: 36px;
}

.organization-box .el-tree .custom-tree-node {
	font-size: 12px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-right: 10px;
}

.organization-box .el-tree .custom-tree-node * {
	font-size: inherit;
}

.organization-box .el-card__body {
	padding: 0px;

	overflow-y: auto;
}


</style>